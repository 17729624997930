

/* ================================= Latest Css Start =========================== */

/* ==================== Shop Sidebar Start ============================= */
.sidebar-shop {
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(#000, 0.05);
  border-radius: 8px;
  .search-form {
    input {
      width: 100%;
      padding: 10px 15px;
      font-size: 14px;
      height: 40px;
    }
    button {
      background-color: transparent;
      font-size: 14px;
      padding: 0 16px;
      height: 40px;
      svg {
        color: var(--heading-color);
      }
    }
  }
}
.sidebar-shop .widget_title {
    color: #212529;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid #e5e5e5e5;
    padding-bottom: 8px;
  }
  .sidebar-shop .widget_title:after {
    display: none;
  }
  
  .sidebar-shop .widget + .widget {
    margin-top: 40px;
  }
  .sidebar-shop .widget .search-form {
    border: 1px solid #E1E1E1;
    border-radius: 8px;
  }
  .sidebar-shop .widget_categories li {
    font-size: 15px;
  }
  .sidebar-shop .widget_categories li:not(:last-child) {
    padding-bottom: 10px;
  }
  .sidebar-shop .widget_categories li a {
    padding: 0;
    border: 0;
  }
  .sidebar-shop .product_ratting_widget ul {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: -0.3em;
  }
  .sidebar-shop .product_ratting_widget ul li {
    color: #212529;
    font-size: 18px;
  }
  .sidebar-shop .product_ratting_widget ul li:not(:last-child) {
    margin-bottom: 5px;
  }
  
  .sidebar-shop .product_ratting_widget ul li .star-rating {
    color: #ffb539;
  }
  .sidebar-shop .product_ratting_widget ul li .star-rating .unavailable {
    color: #212529;
    opacity: 0.3;
  }
  
  .widget_categories ul {
    padding-left: 0;
  }
  
  .widget_categories li {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .sidebar-shop .widget {
    margin-bottom: 32px;
  }
  
  .sidebar-shop .widget:last-child {
    margin-bottom: 0;
  }
  /* ==================== Shop Sidebar End ============================= */
  
  
  
  /* ==================== Product Card Css Start ============================= */
  .product-area-1 {
    padding-bottom: 295px;
    /* Medium devices */
  }
  @media (max-width: 991px) {
    .product-area-1 {
      padding-bottom: 255px;
    }
  }

  .single-select {
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    height: 35px;
    padding: 5px 14px;
    font-size: 14px;
  }
  
  .product-card {
    transition: all ease 0.4s;
    border-radius: 6px;
    background:#fff;
  }
  .product-card.style2 {
    padding: 8px;
    border-radius: 5px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    .product-img {
      margin: 0;
    }
    .product-content {
      padding: 20px 10px 10px 10px;
    }
  }
  .product-card .product-title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  .product-card .product-title a {
    color: inherit;
  }
  .product-card .product-title a:hover {
    color: var(--main-color);
  }
  .product-card .price {
    display: block;
    color: #000;
    font-weight: 700;
    font-size: 16px;
    font-family: var(--heading-font);
    margin-bottom: -0.4em;
    margin-top: 10px;
  }
  .product-card .price del {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #9c9c9c;
  }
  .product-card .product-img {
    background-color:#fff;
    overflow: hidden;
    position: relative;
    text-align: center;
    margin: 20px;
    border-radius: 8px;
    z-index: 2;
  }
  
  .product-card:hover .product-img img {
    transform: scale(1.1);
  }
  
  .product-card .product-img img {
    transition: all ease 0.4s;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }
  .product-card .star-rating {
    font-size: 14px;
    color: #ffb539;
    display: block;
    margin-bottom: 5px;
  }
  .product-card .product-content {
    padding: 0 20px 25px;
  }
  .product-card .tag {
    position: absolute;
    left: 20px;
    top: 20px;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    width: min-content;
  }
  .product-card .tag span {
    display: block;
  }
  .product-card .offer-tag,
  .product-card .product-tag {
    height: 30px;
    font-size: 18px;
    font-weight: 400;
    font-family: var(--heading-font);
    background-color: #E1E1E1;
    line-height: 31px;
    color: var(--heading-color);
    border-radius: 50px;
    padding: 0 10px;
    min-width: 55px;
  }
  .product-card .offer-tag {
    background: var(--main-color);
    color:#fff;
  }

.product-card-bottom {
  padding-top: 10px;
  margin-top: 20px;
  border-top: 1px solid #e5e5e5;
}

  .product-card .link-btn {
    font-size: 14px;
    font-weight: 700;
    color: var(--heading-color);
    letter-spacing: 1px;
    margin-top: 20px;
    position: relative;
    text-transform: capitalize;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    svg {
      width: 11px;
    }
  }
  .product-card .link-btn:before {
    background: var(--heading-color);
  }
  .product-card .icon-btn {
    transform: translateY(30px);
    transition: 0.4s ease-in-out;
  }
  .product-card .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt,
  .product-card .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt {
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
  }
  .product-card .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt::before,
  .product-card .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt::before {
    position: relative;
    top: -1px;
    left: 0;
    line-height: inherit;
    margin: 0;
    font-size: 24px;
  }
  .product-card .tinv-wishlist a {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color:#fff;
    color: var(--heading-color);
    border-radius: 50%;
  }
  .product-card .tinv-wishlist a:hover {
    background-color: var(--main-color);
    color:#fff;
  }
  .product-card .add_to_cart_button.added {
    display: none;
  }
  .product-card .added_to_cart {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: var(--main-color);
    color:#fff;
    font-size: 0;
    text-align: center;
    border-radius: 10px;
  }
  .product-card .added_to_cart:after {
    content: "\f07a";
    position: relative;
    font-family: var(--icon-font);
    font-size: 16px;
    font-weight: 700;
  }
  .product-card .added_to_cart:hover {
    background-color: var(--heading-color);
    color:#fff;
  }
  .product-card .action-btn {
    background-color:#fff;
    font-size: 14px;
    font-family: var(--heading-font);
    text-transform: uppercase;
    font-weight: bold;
    display: inline-block;
    padding: 13px 25px;
  }
  .product-card:hover .product-img:before {
    visibility: visible;
    opacity: 0.7;
  }
  .product-card:hover .actions {
    margin-top: 0;
    opacity: 1;
    visibility: visible;
  }
  .product-card:hover .icon-btn {
    transform: translateY(0);
  }
  .product-card .media {
    display: flex;
    margin-top: 21px;
    margin-bottom: 6px;
  }
  .product-card .media .media-body {
    text-align: right;
  }
  .product-card.list-view {
    display: flex;
    text-align: start;
    height: 100%;
    border: 1px solid #E1E1E1;
    border-radius: 0;
    align-items: center;
    /* Extra small devices */
  }
  @media (max-width: 375px) {
    .product-card.list-view {
      flex-wrap: wrap;
    }
  }
  .product-card.list-view .product-img {
    width: 100%;
    max-width: 213px;
    margin: 0;
    /* Extra small devices */
  }
  @media (max-width: 375px) {
    .product-card.list-view .product-img {
      padding: 10px 10px 20px;
    }
  }
  .product-card.list-view .star-rating {
    width: 93px;
  }
  .product-card.list-view .product-content {
    flex: 1;
    border-left: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 25px 20px 25px 0;
    /* Extra small devices */
  }
  @media (max-width: 375px) {
    .product-card.list-view .product-content {
      padding: 0 25px 25px 25px;
    }
  }
  .product-card.list-view .actions {
    --btn-size: 35px;
    --btn-font-size: 13px;
    --icon-gap-x: 2px;
  }
  .product-card.list-view .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt,
  .product-card.list-view .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
  .product-card.list-view .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt::before,
  .product-card.list-view .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt::before {
    font-size: 20px;
  }
  .product-card.list-view .tinv-wishlist a {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
  .product-card.list-view .added_to_cart {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
  .product-card.list-view .added_to_cart:after {
    font-size: 16px;
  }
  .product-card.list-view .action-btn {
    padding: 8px 15px;
  }
  .product-card.list-view .product-title {
    font-size: 26px;
    margin: 0 0 5px 0;
  }
  .product-card.list-view .product-price {
    font-size: 14px;
  }
  .product-card .link-btn:before {
    background: var(--heading-color);
  }
  
  .link-btn:hover::before {
    width: 100%;
  }
  
  .link-btn:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: var(--heading-color);
    transition: all ease 0.4s;
  }
  .shop-sort-bar {
    padding: 0;
    margin: 0 0 24px 0;
  }
  .product-card .product-title > a {
    font-size: 16px;
    font-weight: 700;
  }
  /* ==================== Product Card Css End ============================= */
  
  /* Pagination Start  */
  .pagination {
    display: flex;
    gap: 10px;
    margin-bottom: 0 !important;
    .page-item {
      &.active {
        .page-link {
          background-color: #fff;
          border-color: var(--main-color);
          color: var(--main-color);
        }
      }
    }
    .page-link {
      width: 40px;
      height: 40px;
      border: 1px solid #e5e5e5;
      border-radius: 50% !important;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--paragraph-color);
      font-weight: 700;
      &:focus {
        box-shadow: none;
      }
    }
  }
  
  /* Medium devices */
  @media (max-width: 991px) {
    .pagination {
      margin-top: 60px;
    }
  }
  /* Small devices */
  @media (max-width: 767px) {
    .pagination {
      margin-top: 40px;
    }
    .pagination span,
    .pagination a {
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
    }
    .pagination span i,
    .pagination a i {
      font-size: 16px;
    }
  }
  @media (max-width: 330px) {
    .pagination li {
      margin: 0 2px;
    }
    .pagination span, .pagination a {
      width: 35px;
      height: 35px;
      line-height: 34px;
      font-size: 14px;
    }
  }
  /* Pagination End */
  
  /* Range Slider Start */
  /*Price Filter --------------*/
  .price_slider_wrapper {
    margin-top: 0;
  }
  .price_slider_wrapper .price_label {
    margin-bottom: -0.6em;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: var(--heading-font);
    color: var(--light-color);
  }
  .price_slider_wrapper .price_label span {
    display: inline-block;
    color: var(--light-color);
  }
  .price_slider_wrapper .ui-slider {
    height: 1px;
    position: relative;
    width: 100%;
    background-color: #e0e0e0;
    border: none;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 0;
  }
  .price_slider_wrapper .ui-slider-range {
    border: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 1;
    display: block;
    background-color: var(--main-color);
  }
  .price_slider_wrapper .ui-slider-handle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    text-align: center;
    line-height: 10.5px;
    padding: 0;
    border: none;
    cursor: pointer;
    position: absolute;
    margin-top: -7px;
    z-index: 2;
    background-color: #fff;
    border: 1px solid var(--main-color);
    transform: translateX(-1px);
  }
  .price_slider_wrapper .ui-slider-handle:focus {
    outline: none;
    box-shadow: 1.5px 2.598px 10px 0px rgba(0, 0, 0, 0.15);
  }
  .price_slider_wrapper .ui-slider-handle:last-child {
    transform: translateX(-9px);
  }
  .price_slider_wrapper button,
  .price_slider_wrapper .button {
    background: transparent;
    color: var(--heading-color);
    font-weight: 600;
    line-height: 1.6;
    text-transform: uppercase;
    text-align: center;
    border-radius: 50px;
    border: none;
    display: inline-block;
    overflow: hidden;
    position: relative;
    z-index: 2;
    padding: 0;
    font-size: 16px;
    transition: 0.4s ease-in;
    margin-left: auto;
  }
  .price_slider_wrapper button:hover,
  .price_slider_wrapper .button:hover {
    color: var(--main-color);
  }
  .price_slider_wrapper button:hover:after, .price_slider_wrapper button:hover:before,
  .price_slider_wrapper .button:hover:after,
  .price_slider_wrapper .button:hover:before {
    display: none;
  }
  .btn.btn--black {
    background-color: var(--heading-color);
    height: unset;
    line-height: unset;
    border-radius: 8px !important;
    overflow: hidden;
    font-size: 12px;
    text-transform: uppercase;
    padding: 17px 30px;
    color: #fff !important;
  }
  /* Range Slider End */
  
  
  /* ======================== product Details Css Start ========================= */
  .summary-content,
  .product-about {
    padding-left: 24px;
  }
  .summary-content .product-title,
  .product-about .product-title {
    margin: -0.2em 0 15px 0;
    font-weight: 800;
    font-size: 36px;
  }
  .summary-content > .price,
  .product-about > .price {
    font-size: 48px;
    font-weight: 500;
    color: var(--main-color);
    display: block;
    margin-bottom: 25px;
    margin-right: 10px;
    font-family: var(--heading-font);
    line-height: 1;
  }
  .summary-content > .price del,
  .product-about > .price del {
    color: #D3D3D3;
    font-weight: 400;
    font-size: 30px;
    margin-left: 5px;
  }
  .summary-content .product-rating,
  .product-about .product-rating {
    display: inline-flex;
    gap: 5px;
    align-items: center;
    position: relative;
    top: 2px;
    font-size: 16px;
    line-height: 20px;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    margin-bottom: 25px;
  }
  .summary-content .product-rating .star-rating,
  .product-about .product-rating .star-rating {
    font-size: 12px;
    margin-right: 8px;
    color: #ffb539;
  }
  .summary-content .woocommerce-review-link,
  .product-about .woocommerce-review-link {
    color: var(--paragraph-color);
  }
  .summary-content .checklist,
  .product-about .checklist {
    margin: 30px 0 40px 0;
  }
  .summary-content .checklist li,
  .product-about .checklist li {
    font-weight: 400;
  }
  .summary-content .actions,
  .product-about .actions {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin: 33px 0 27px 0;
    align-items: center;
  }
  .summary-content .actions .btn,
  .product-about .actions .btn {
    padding: 18px 30px;
  }
  .summary-content .actions .btn .btn-icon,
  .product-about .actions .btn .btn-icon {
    padding: 10.5px 15px 10.5px 15px;
  }
  .summary-content .actions .icon-btn,
  .product-about .actions .icon-btn {
    border-color: #ddd;
  }
  .summary-content .actions .icon-btn:hover,
  .product-about .actions .icon-btn:hover {
    background-color: var(--main-color);
    color: #fff;
    border-color: var(--main-color);
  }
  .summary-content .share,
  .product-about .share {
    margin-top: 25px;
  }
  .summary-content .social-btn a,
  .product-about .social-btn a {
    --icon-size: 40px;
    line-height: 38px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 0;
  }
  .summary-content .social-btn a:hover,
  .product-about .social-btn a:hover {
    border-color: var(--main-color);
  }
  
  .product_meta {
    font-weight: 700;
    font-size: 16px;
    font-family: var(--body-font);
    margin: 35px 0 0 0;
  }
  .product_meta > span {
    display: block;
    margin-bottom: 5px;
    color: var(--heading-color);
    font-weight: bold;
  }
  .product_meta > span:last-child {
    margin-bottom: 0;
  }
  .product_meta > span a {
    color: inherit;
  }
  .product_meta > span a:hover {
    color: var(--main-color);
  }
  .product_meta > span > a,
  .product_meta > span > span {
    position: relative;
    color: var(--paragraph-color);
    font-weight: 400;
  }
  .product_meta > span > a:after,
  .product_meta > span > span:after {
    content: ",";
    margin-right: 5px;
  }
  .product_meta > span > a:last-child:after,
  .product_meta > span > span:last-child:after {
    display: none;
  }
  .product_meta > span > a:first-child,
  .product_meta > span > span:first-child {
    margin-left: 7px;
  }
  
  .product-tab-area {
    margin-top: 80px;
  }
  
  .product-thumb .product-tag,
  .product-big-img .product-tag {
    height: 25px;
    font-size: 14px;
    font-weight: 500;
    background-color: var(--main-color);
    line-height: 25px;
    color: #fff;
    border-radius: 5px;
    position: absolute;
    padding: 0 10px;
    top: 23px;
    left: 23px;
    z-index: 9;
  }
  .product-thumb {
    position: relative;
  }
  
  .product-thumb {
    position: relative;
  }
  
  .product-thumb {
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .product-thumb .img {
    height: 100%;
  }
  
  .product-thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .product-tab-area {
    margin-top: 80px;
  }
  
  .product-tab-style1 {
    border-bottom: 2px solid #EFEFEF;
    margin: 0px auto 40px auto;
    padding-bottom: 20px;
    justify-content: center;
    gap: 30px;
  }
  .product-tab-style1 .nav-link {
    background-color: transparent;
    color: var(--heading-color);
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    font-family: var(--heading-font);
    position: relative;
    text-transform: uppercase;
  }
  .product-tab-style1 .nav-link:after {
    content: "";
    height: 2px;
    background: var(--main-color);
    width: 0;
    left: 0;
    bottom: -22px;
    position: absolute;
    transition: 0.4s all;
  }
  .product-tab-style1 .nav-link:hover, .product-tab-style1 .nav-link.active {
    color: var(--main-color);
  }
  .product-tab-style1 .nav-link:hover:after, .product-tab-style1 .nav-link.active:after {
    width: 100%;
  }
  
  
  
  
  .woocommerce-table {
    margin-bottom: 0;
  }
  
  .woocommerce-Reviews .comments-wrap {
    padding: 0;
    box-shadow: none;
  }
  .woocommerce-Reviews .comment-form {
    background-color: transparent;
    box-shadow: none;
    margin-bottom: 0;
    margin-top: var(--blog-space-y, 30px);
  }
  .woocommerce-Reviews .comment-form input,
  .woocommerce-Reviews .comment-form .form-control {
    background-color: var(--white-color);
  }
  .woocommerce-Reviews .comment-form .blog-inner-title {
    margin-bottom: 10px;
  }
  .woocommerce-Reviews .comment-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 24px;
    margin-bottom: 40px;
  }
  .woocommerce-Reviews .post-comment {
    padding: 40px !important;
    margin-bottom: 24px;
    background: var(--white-color);
    border: 0 !important;
    margin-bottom: 0;
  }
  .woocommerce-Reviews .post-comment .name {
    margin-bottom: 3px;
  }
  .woocommerce-Reviews .post-comment .commented-on {
    margin-bottom: 12px;
  }
  .woocommerce-Reviews .post-comment .text {
    margin-bottom: -0.5em;
  }
  
  /* Large devices */
  @media (max-width: 1199px) {
    .woocommerce-Reviews .post-comment {
      padding: 30px !important;
    }
  }
  /* Medium devices */
  @media (max-width: 991px) {
    .woocommerce-Reviews .comment-list {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  /* Extra small devices */
  @media (max-width: 575px) {
    .woocommerce-Reviews .comment-form {
      padding: 40px 20px;
    }
  }
  .product-inner-list > ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .product-inner-list li {
    position: relative;
    padding-left: 15px;
  }
  .product-inner-list li:before {
    content: "-";
    position: absolute;
    left: 0;
  }
  
  .share-title {
    font-size: 16px;
    font-weight: 600;
    color: var(--heading-color);
    margin-bottom: 8px;
  }
  .share-title i {
    color: var(--main-color);
  }
  
  .product-slider-wrap {
    background-color: var(--smoke-color3);
    text-align: center;
    border-radius: 6px;
    height: 100%;
    padding: 0 0 50px;
  }
  .product-slider-wrap .product-thumb {
    padding-top: 100px;
  }
  .product-slider-wrap .product-indicator {
    display: inline-flex;
    gap: 15px;
    margin-top: 40px;
  }
  .product-slider-wrap .product-indicator .indicator-btn {
    z-index: 99;
    position: relative;
    background-color: rgb(255, 255, 255);
    border-radius: 6px;
    width: 87px;
    height: 87px;
    display: inline-block;
    line-height: 85px;
    cursor: pointer;
    border: 1px solid var(--white-color);
  }
  .product-slider-wrap .product-indicator .indicator-btn.active {
    border: 1px solid var(--main-color);
  }
  .product-slider-wrap .product-indicator .indicator {
    display: none;
  }
  
  .summary-content,
  .product-about {
    padding-left: 24px;
  }
  .summary-content .product-title,
  .product-about .product-title {
    margin: -0.2em 0 15px 0;
    font-weight: 800;
    font-size: 36px;
  }
  .summary-content > .price,
  .product-about > .price {
    font-size: 48px;
    font-weight: 500;
    color: var(--main-color);
    display: block;
    margin-bottom: 25px;
    margin-right: 10px;
    font-family: var(--heading-font);
    line-height: 1;
  }
  .summary-content > .price del,
  .product-about > .price del {
    color: #D3D3D3;
    font-weight: 400;
    font-size: 30px;
    margin-left: 5px;
  }
  .summary-content .product-rating,
  .product-about .product-rating {
    display: inline-flex;
    gap: 5px;
    align-items: center;
    position: relative;
    top: 2px;
    font-size: 16px;
    line-height: 20px;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    margin-bottom: 25px;
  }
  .summary-content .product-rating .star-rating,
  .product-about .product-rating .star-rating {
    font-size: 12px;
    margin-right: 8px;
    color: #ffb539;
  }
  .summary-content .woocommerce-review-link,
  .product-about .woocommerce-review-link {
    color: var(--paragraph-color);
  }
  .summary-content .checklist,
  .product-about .checklist {
    margin: 30px 0 40px 0;
  }
  .summary-content .checklist li,
  .product-about .checklist li {
    font-weight: 400;
  }
  .summary-content .actions,
  .product-about .actions {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin: 33px 0 27px 0;
    align-items: center;
  }
  .summary-content .actions .btn,
  .product-about .actions .btn {
    padding: 18px 30px;
  }
  .summary-content .actions .btn .btn-icon,
  .product-about .actions .btn .btn-icon {
    padding: 10.5px 15px 10.5px 15px;
  }
  .summary-content .actions .icon-btn,
  .product-about .actions .icon-btn {
    border-color: #e1e1e1;
  }
  .summary-content .actions .icon-btn:hover,
  .product-about .actions .icon-btn:hover {
    background-color: var(--main-color);
    color: var(--white-color);
    border-color: var(--main-color);
  }
  .summary-content .share,
  .product-about .share {
    margin-top: 25px;
  }
  .summary-content .social-btn a,
  .product-about .social-btn a {
    --icon-size: 40px;
    line-height: 38px;
    font-size: 14px;
    border: 1px solid #e1e1e1;
    border-radius: 0;
  }
  .summary-content .social-btn a:hover,
  .product-about .social-btn a:hover {
    border-color: var(--main-color);
  }
  .summary-content .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt,
  .summary-content .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt,
  .product-about .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt,
  .product-about .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt {
    width: 50px;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    border-radius: 10px;
  }
  .summary-content .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt::before,
  .summary-content .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt::before,
  .product-about .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt::before,
  .product-about .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt::before {
    position: relative;
    top: 0;
    left: 0;
    line-height: inherit;
    margin: 0;
    font-size: 24px;
  }
  .summary-content .tinv-wishlist a,
  .product-about .tinv-wishlist a {
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 0;
  }
  .summary-content .tinv-wishlist a:hover,
  .product-about .tinv-wishlist a:hover {
    background-color: var(--main-color);
    color: var(--white-color);
  }
  .summary-content .quantity,
  .product-about .quantity {
    position: relative;
    background: var(--heading-color);
    border-radius: 5px;
    margin-right: 0px;
    height: 50px;
  }
  .summary-content .quantity .qty-input,
  .product-about .quantity .qty-input {
    width: 50px;
    height: 50px;
    background-color: transparent;
    color: var(--white-color);
    text-align: center;
    font-weight: 500;
    border-left: 1px solid #3c3c3c;
    border-right: 1px solid #3c3c3c;
  }
  .summary-content .quantity .qty-btn,
  .product-about .quantity .qty-btn {
    color: var(--white-color);
    background-color: transparent;
    right: 21px;
    border: none;
    line-height: 50px;
  }
  
  .product-details .comments-wrap {
    margin-top: 0;
  }
  .product-details .border-title {
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 40px;
  }
  .product-details .border-title:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 80px;
    background-color: var(--main-color);
  }
  
  .product-inner-title {
    font-size: 32px;
    border-bottom: 1px solid #e1e1e1;
    padding: 0 0 7px 0;
    margin: 0 0 30px 0;
  }
  
  .related-product-wrapper {
    padding-top: 115px;
  }
  
  .woosq-product .thumbnails img {
    padding: 10px;
  }
  
  .woosq-product .thumbnails .slick-dots {
    bottom: auto;
    margin-top: 30px;
  }
  .woosq-product .thumbnails .slick-dots li ~ li {
    margin-left: 5px;
  }
  .woosq-product .thumbnails .slick-dots li button {
    background: var(--main-color);
    position: relative;
  }
  .woosq-product .thumbnails .slick-dots li button:before {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    top: 14px;
    left: 14px;
  }
  
  #woosq-popup .single-product .product .tinvwl-shortcode-add-to-cart {
    display: none;
  }
  
  .mfp-woosq .mfp-close {
    width: 44px;
    height: 44px;
    line-height: initial;
  }
  
  /* Medium Large devices */
  @media (max-width: 1399px) {
    .product.list-view .product-img {
      max-width: 150px;
    }
    .woocommerce-Reviews .post-comment {
      display: block;
    }
    .woocommerce-Reviews .post-comment .comment-avater img {
      width: auto;
      margin: 0 0 30px;
    }
  }
  /* Medium Large devices */
  @media (max-width: 1299px) {
    .product-thumb-tab {
      --thumb: 100px;
      margin-left: -40px;
    }
    .product-thumb-area {
      margin-right: 0;
    }
  }
  /* Large devices */
  @media (max-width: 1199px) {
    .shop-sort-bar select {
      min-width: auto;
    }
    .summary-content,
    .product-about {
      padding-left: 0;
      margin-top: 35px;
    }
    .summary-content .actions, .product-about .actions {
      margin: 0;
    }
    .product-big-img .img {
      height: 540px;
    }
    .product-slider-wrap .product-indicator .indicator-btn {
      width: 70px;
      height: 70px;
      line-height: 70px;
    }
    .product-slider-wrap {
      padding: 0 0 30px;
    }
  }
  /* Medium devices */
  @media (max-width: 991px) {
    .product-big-img {
      margin-bottom: 40px;
      margin-left: 50px;
    }
    .product-thumb-tab {
      margin-left: -10px;
    }
    .product-box.list-view .product-img {
      max-width: 150px;
    }
    .shop-sort-bar .row {
      --bs-gutter-x: 20px;
    }
    .shop-sort-bar .nav a:last-child {
      margin-right: 0;
      padding-right: 0;
    }
    .shop-sort-bar .nav a:last-child:before {
      display: none;
    }
    .woosq-product > .product .thumbnails {
      max-height: 400px;
      min-height: 200px;
      padding: 10px;
    }
    .woocommerce-Reviews .comment-item ~ .comment-item {
      margin-top: 20px;
    }
  }
  /* Small devices */
  @media (max-width: 767px) {
    .shop-sort-bar {
      text-align: center;
    }
    .shop-sort-bar .nav {
      justify-content: center;
    }
    .shop-sort-bar .single-select,
    .shop-sort-bar select {
      margin: 0 auto;
      width: 100%;
    }
    .shop-sort-bar .woocommerce-result-count {
      padding-left: 0;
    }
    .product-box.list-view .product-img {
      max-width: 130px;
    }
    .product-box.list-view .actions {
      --btn-size: 30px;
      --btn-font-size: 10px;
      --icon-gap-x: 2px;
    }
  }
  /* Extra small devices */
  @media (max-width: 575px) {
    .product-about .actions {
      gap: 15px 15px;
    }
    .product-thumb-tab {
      --thumb: 74px;
      --gap: 6px;
    }
    .product-thumb-tab .tab-btn {
      border-radius: 6px;
    }
    .product-thumb-tab .tab-btn:not(:last-of-type) {
      margin-bottom: 10px;
    }
    .product-thumb-tab .indicator {
      border-radius: 6px;
    }
    .product-big-img {
      margin-left: 28px;
    }
    .product-big-img .img {
      height: 370px;
    }
    .summary-content .product-title, .product-about .product-title {
      font-size: 32px;
    }
    .summary-content > .price, .product-about > .price {
      font-size: 24px;
      margin-bottom: 9px;
    }
    .product-tab-area {
      margin-top: 40px;
    }
    .product-tab-style1 {
      border: 0;
      gap: 15px;
    }
    .product-tab-style1 .nav-link:after {
      bottom: -4px;
    }
  }
  /* Extra small devices */
  @media (max-width: 375px) {
    .product.list-view .product-img {
      max-width: 130px;
    }
    .product-slider-wrap .product-indicator .indicator-btn {
      width: 60px;
      height: 60px;
      line-height: 60px;
    }
    .product-slider-wrap .product-indicator {
      gap: 10px;
    }
    .summary-content .product-title, .product-about .product-title {
      font-size: 28px;
    }
  }
  .product-intro-card {
    display: flex;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    overflow: hidden;
    /* Large devices */
    /* Medium devices */
    /* Extra small devices */
  }
  @media (max-width: 1199px) {
    .product-intro-card {
      flex-wrap: wrap;
    }
  }
  @media (max-width: 991px) {
    .product-intro-card {
      flex-wrap: nowrap;
    }
  }
  @media (max-width: 375px) {
    .product-intro-card {
      flex-wrap: wrap;
    }
  }
  .product-intro-card .intro-card-details {
    padding: 80px 0 80px 36px;
    /* Large devices */
    /* Medium devices */
  }
  @media (max-width: 1199px) {
    .product-intro-card .intro-card-details {
      padding-bottom: 0;
    }
  }
  @media (max-width: 991px) {
    .product-intro-card .intro-card-details {
      padding: 50px 30px 0;
    }
  }
  .product-intro-card .intro-card-img {
    align-self: flex-end;
  }
  .product-intro-card .intro-card-subtitle {
    color: var(--main-color);
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 18px;
  }
  .product-intro-card .intro-card-title {
    font-size: 36px;
    font-weight: 800;
    line-height: 1;
    margin-bottom: 22px;
    /* Medium Large devices */
  }
  @media (max-width: 1399px) {
    .product-intro-card .intro-card-title {
      font-size: 30px;
    }
  }
  .product-intro-card .intro-card-title a {
    color: var(--heading-color);
  }
  .product-intro-card .intro-card-title a:hover {
    color: var(--main-color);
  }
  .product-intro-card .btn {
    font-size: 14px;
    font-weight: 700;
    padding: 9px 10px;
    border-color: #E1E1E1;
  }
  .product-intro-card .btn i {
    font-size: 12px;
  }
  .product-intro-card .intro-card-img {
    align-self: flex-end;
    flex: none;
    margin-left: -100px;
    /* Medium Large devices */
    /* Large devices */
  }
  @media (max-width: 1399px) {
    .product-intro-card .intro-card-img {
      margin-left: 0;
    }
  }
  @media (max-width: 1199px) {
    .product-intro-card .intro-card-img {
      margin-left: auto;
    }
  }
  .product-intro-card.style2 {
    display: block;
    /* Large devices */
  }
  .product-intro-card.style2 .intro-card-details {
    padding: 60px 90px 80px 55px;
    /* Large devices */
  }
  @media (max-width: 1199px) {
    .product-intro-card.style2 .intro-card-details {
      padding: 65px 30px 80px 36px;
    }
  }
  .product-intro-card.style2 .intro-card-img {
    margin-left: 0;
    width: 100%;
  }
  @media (max-width: 1199px) {
    .product-intro-card.style2 .intro-card-img {
      text-align: end;
    }
    .product-intro-card.style2 .intro-card-details {
      padding-top: 0;
      padding-bottom: 50px;
    }
  }
  .product-intro-card.style3 {
    align-items: center;
    /* Large devices */
    /* Small devices */
  }
  .product-intro-card.style3 .intro-card-img {
    margin-left: 0;
  }
  .product-intro-card.style3 .intro-card-details {
    padding: 60px 30px 60px 20px;
  }
  @media (max-width: 1199px) {
    .product-intro-card.style3 {
      display: flex;
    }
  }
  @media (max-width: 767px) {
    .product-intro-card.style3 {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
    .product-intro-card.style3 .intro-card-details {
      padding: 50px 30px 0px 30px;
    }
    .product-intro-card.style3 .intro-card-img {
      margin-left: 0;
      margin-right: auto;
    }
  }
  
  
  table.my-table {
    margin: 0 0 1.5em;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #e1e1e1;
  }
  
  .my-table th {
    font-weight: 700;
    color: var(--heading-color);
  }
  
  .my-table td,
  .my-table th {
    border: 1px solid #e1e1e1;
    padding: 9px 12px;
  }
  
  
  /* Comment Css Start  */
  
  .comments-wrap {
    margin-top: var(--blog-space-y, 80px);
    margin-bottom: 30px;
  }
  .comments-wrap .description p:last-child {
    margin-bottom: -0.5em;
  }
  .comments-wrap .comment-respond {
    margin: 30px 0;
  }
  .comments-wrap pre {
    background: #ededed;
    color: #666;
    font-size: 14px;
    margin: 20px 0;
    overflow: auto;
    padding: 20px;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  .comments-wrap li {
    margin: 0;
  }
  .comments-wrap .post-comment {
    padding: 0;
    position: relative;
    display: flex;
    margin-bottom: 30px;
    padding-bottom: 30px;
    position: relative;
    border-bottom: 1px solid #e1e1e1;
  }
  .comments-wrap .post-comment ol,
  .comments-wrap .post-comment ul,
  .comments-wrap .post-comment dl {
    margin-bottom: 1rem;
  }
  .comments-wrap .post-comment ol ol,
  .comments-wrap .post-comment ol ul,
  .comments-wrap .post-comment ul ol,
  .comments-wrap .post-comment ul ul {
    margin-bottom: 0;
  }
  .comments-wrap ul.comment-list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: var(--blog-space-y, 80px);
  }
  .comments-wrap ul.comment-list ul ul,
  .comments-wrap ul.comment-list ul ol,
  .comments-wrap ul.comment-list ol ul,
  .comments-wrap ul.comment-list ol ol {
    margin-bottom: 0;
  }
  .comments-wrap .comment-avater {
    margin-right: 25px;
    overflow: hidden;
    border-radius: 15px;
    height: fit-content;
  }
  .comments-wrap .comment-avater img {
    width: 100%;
    max-width: 100px;
    height: 100px;
    object-fit: cover;
  }
  .comments-wrap .comment-content {
    flex: 1;
    margin-top: -6px;
    position: relative;
  }
  .comments-wrap .commented-on {
    font-size: 12px;
    display: inline-block;
    margin-bottom: 2px;
    font-weight: 500;
    color: var(--body-color);
    font-family: var(--heading-font);
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .comments-wrap .commented-on i {
    margin-right: 7px;
    font-size: 0.9rem;
  }
  .comments-wrap .name {
    margin-bottom: 7px;
    font-size: 20px;
    font-weight: 700;
  }
  .comments-wrap .comment-top {
    display: flex;
    justify-content: space-between;
  }
  .comments-wrap .text {
    margin-bottom: -0.3em;
  }
  .comments-wrap .children {
    margin: 0;
    padding: 0;
    list-style-type: none;
    margin-left: 135px;
  }
  .comments-wrap .reply_and_edit {
    margin-bottom: -0.46em;
    position: absolute;
    right: 0;
    top: 20px;
  }
  .comments-wrap .reply_and_edit a {
    margin-right: 10px;
    color: var(--main-color);
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    font-family: var(--body-font);
  }
  .comments-wrap .reply_and_edit a:hover {
    color: var(--heading-color);
  }
  .comments-wrap .reply_and_edit a i {
    margin-left: 3px;
    margin-right: 0;
  }
  .comments-wrap .reply_and_edit a:last-child {
    margin-right: 0;
  }
  .comments-wrap .reply-btn {
    font-weight: 700;
    font-size: 12px;
    color: var(--paragraph-color);
    font-family: var(--title-font);
    display: inline-block;
  }
  .comments-wrap .reply-btn i {
    margin-right: 7px;
  }
  .comments-wrap .reply-btn:hover {
    color: var(--main-color);
  }
  .comments-wrap .star-rating {
    font-size: 12px;
    margin-bottom: 10px;
    position: absolute;
    top: 5px;
    right: 0;
    width: 80px;
  }
  
  ul.comment-list .comment-item:last-child:not(.children .comment-item) > .post-comment {
    border-bottom: none;
    padding-bottom: 0;
  }
  ul.comment-list .comment-item:first-child:not(.children .comment-item) > .post-comment {
    padding-bottom: 30px;
    border-bottom: 1px solid #e1e1e1;
  }
  
  .comments-wrap.comment-form {
    margin: 0;
  }
  
  
  
  .comment-form {
    margin-top: 20px;
    position: relative;
    padding: 60px;
    border-radius: 5px;
    background: #f4f4f4;
  }
  .comment-form .row {
    --bs-gutter-x: 20px;
  }
  .comment-form .form-title {
    margin-top: -0.35em;
    margin-bottom: 30px;
  }
  
  .form-group {
    position: relative;
  }
  .form-group > i, .form-group > svg {
    display: inline-block;
    position: absolute;
    right: 25px;
    top: 21px;
    font-size: 16px;
    color: var(--paragraph-color);
  }
  .single-input-inner input:focus, .single-input-inner textarea:focus {
    border: 1px solid var(--main-color);
  }
  /* Comment Css End */
  /* ======================== product Details Css End ========================= */
  
  
  /* ======================== Breadcrumb Four Css Start ========================= */
  .process-list {
    margin-top: clamp(2rem, -1.195rem + 6.656vw, 4.625rem);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 16px;
    padding: 12px;
    backdrop-filter: blur(5px);
    gap: 10px;
    list-style: none;
  }
  
  @media screen and (max-width: 767px) {
    .process-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
  }
  
  .process-list__item:last-child .process-list__link::after {
    display: none;
  }
  
  .process-list__item.activePage .process-list__link, .process-list__item:hover .process-list__link {
    color: #fff;
    border-color: transparent;
  }
  
  .process-list__item.activePage .process-list__link::before, .process-list__item:hover .process-list__link::before {
    visibility: visible;
    opacity: 1;
  }
  
  .process-list__item.activePage .process-list__link::after, .process-list__item:hover .process-list__link::after {
    border-color: var(--heading-color);
  }
  
  .process-list__item.activePage .process-list__link .icons .icon.white, .process-list__item:hover .process-list__link .icons .icon.white {
    visibility: visible;
    opacity: 1;
  }
  
  .process-list__item.activePage .process-list__link .icons .icon.colored, .process-list__item:hover .process-list__link .icons .icon.colored {
    visibility: hidden;
    opacity: 0;
  }
  
  .process-list__link {
    padding: 14px 26px;
    text-align: center;
    background-color: #fff;
    border-radius: 16px;
    position: relative;
    z-index: 1;
    color: var(--paragraph-color);
    border: 1px solid #e1e1e1;
    width: 100%;
    font-size: 0.875rem;
  }
  
  @media screen and (max-width: 991px) {
    .process-list__link {
        padding: 16px;
    }
  }
  
  @media screen and (max-width: 767px) {
    .process-list__link {
        padding: 16px 10px;
        font-size: 0.75rem;
    }
  }
  
  .process-list__link::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: var(--main-color);
    left: 0;
    top: 0;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    transition: 0.2s linear;
  }
  
  .process-list__link::after {
    position: absolute;
    content: "";
    width: 130px;
    height: 1px;
    right: -100%;
    top: 50%;
    transform: translateY(-50%);
    border: 1px dashed #e1e1e1;
  }
  
  @media screen and (max-width: 1199px) {
    .process-list__link::after {
        display: none;
    }
  }
  
  .process-list__link .icons {
    width: 26px;
    height: 26px;
    position: relative;
    margin: 0 auto;
  }
  
  .process-list__link .icons .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    transition: 0.2s linear;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .process-list__link .icons .icon.white {
    visibility: hidden;
    opacity: 0;
  }
  
  .process-list__link .text {
    color: inherit;
    margin-top: 8px;
    font-size: inherit;
  }
  
  .pill, .btn.pill {
    border-radius: 50px !important;
  }
  /* ======================== Breadcrumb Four Css End ========================= */
  
  /* ============================ Cart Page Css Start ===================== */
  .cart-content__bottom {
    margin-top: 64px;
  }
  
  @media screen and (max-width: 1199px) {
    .cart-content__bottom {
        margin-top: 48px;
    }
  }
  
  @media screen and (max-width: 991px) {
    .cart-content__bottom {
        margin-top: 40px;
    }
  }
  
  @media screen and (max-width: 767px) {
    .cart-content__bottom {
        margin-top: 32px;
    }
  }
  .cart-item {
    max-width: 342px;
  }
  
  .cart-item__thumb {
    width: 164px;
    height: 130px;
    flex-shrink: 0;
    display: flex;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .cart-item__count {
    width: auto;
    display: inline-flex;
    align-items: center;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    margin: 0 auto;
  }
  
  .cart-item__count input {
    background: transparent;
    border: 0;
    text-align: center;
    width: 44px;
    color: var(--heading-color);
    font-size: 16px;
  }
  
  .cart-item__count input:focus {
    outline: none;
    border: 0;
  }
  
  .cart-item__count button {
    width: 40px;
    height: 40px;
    font-size: 16px;
    color: var(--paragraph-color);
    border-radius: inherit;
    border: 0;
    background-color: transparent;
    cursor: pointer;
  }
  
  .cart-item__count button:hover {
    color: var(--main-color);
  }
  
  /* Chrome, Safari, Edge, Opera */
  .cart-item__count input::-webkit-outer-spin-button,
  .cart-item__count input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  .cart-item__count input[type=number] {
    -moz-appearance: textfield;
  }
  .cart-item__thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .product-card__wishlist {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 40px;
    height: 40px;
    border-radius: 30px;
    background-color: hsl(var(--white)/0.5);
    backdrop-filter: blur(5px);
    color: var(--heading-color);
    z-index: 2;
    transition: 0.2s linear;
  }
  
  .product-card__wishlist.style-two {
    position: relative;
    top: 0;
    right: 0;
    background-color: #e1e1e1;
    border: 0;
    width: 32px;
    height: 32px;
    font-size: 0.75rem;
  }
  
  .product-card__wishlist.style-two:hover {
    background-color: var(--heading-color);
    color: #fff;
  }
  
  .product-card__wishlist:hover {
    background-color: #fff;
  }
  
  /* ============================ Cart Page Css End ===================== */
  
  .fw-200 {
    font-weight: 200 !important;
  }
  
  .fw-300 {
    font-weight: 300 !important;
  }
  
  .fw-400 {
    font-weight: 400 !important;
  }
  
  .fw-500 {
    font-weight: 500 !important;
  }
  
  .fw-600 {
    font-weight: 600 !important;
  }
  
  .fw-700 {
    font-weight: 700 !important;
  }
  
  .fw-800 {
    font-weight: 800 !important;
  }
  
  .fw-900 {
    font-weight: 900 !important;
  }
  
  .left-auto {
    left: auto !important;
  }
  
  
  /* Font Size For responsive devices Start */
  .font-10 {
    font-size: 0.625rem !important;
  }
  
  .font-11 {
    font-size: 0.6875rem !important;
  }
  
  .font-12 {
    font-size: 0.75rem !important;
  }
  
  .font-13 {
    font-size: 0.8125rem !important;
  }
  
  .font-14 {
    font-size: 0.875rem !important;
  }
  
  .font-15 {
    font-size: 0.9375rem !important;
  }
  
  .font-16 {
    font-size: 1rem !important;
  }
  
  .font-17 {
    font-size: 1.0625rem !important;
  }
  
  .font-18 {
    font-size: 1.125rem !important;
  }
  
  @media screen and (max-width: 1199px) {
    .font-18 {
        font-size: 1rem !important;
    }
  }
  
  @media screen and (max-width: 767px) {
    .font-18 {
        font-size: 0.9375rem !important;
    }
  }
  
  .font-19 {
    font-size: 1.1875rem !important;
  }
  
  .font-20 {
    font-size: 1.25rem !important;
  }
  
  @media screen and (max-width: 1199px) {
    .font-20 {
        font-size: 1.125rem !important;
    }
  }
  
  @media screen and (max-width: 767px) {
    .font-20 {
        font-size: 1rem !important;
    }
  }
  
  .font-21 {
    font-size: 1.3125rem !important;
  }
  
  .font-22 {
    font-size: 1.375rem !important;
  }
  
  .font-23 {
    font-size: 1.4375rem !important;
  }
  
  .font-24 {
    font-size: 1.5rem !important;
  }
  
  .font-25 {
    font-size: 1.5625rem !important;
  }
  
  .font-26 {
    font-size: 1.625rem !important;
  }
  
  .font-27 {
    font-size: 1.6875rem !important;
  }
  
  .font-28 {
    font-size: 1.75rem !important;
  }
  
  .font-29 {
    font-size: 1.8125rem !important;
  }
  
  .font-30 {
    font-size: 1.875rem !important;
  }
  
  .font-31 {
    font-size: 1.9375rem !important;
  }
  
  .font-32 {
    font-size: 2rem !important;
  }
  
  /* Table Css Start  */
  
  td {
    vertical-align: middle;
  }
  
  .common-table tbody tr {
    border-bottom: 1px solid #e1e1e1;
  }
  .common-table td {
    padding: 16px !important;
  }
  
  .common-table {
    border-spacing: 0px;
    border-collapse: separate;
    min-width: max-content;
  }
  
  .common-table tbody tr td:first-child {
    border-left: 1px solid #e1e1e1;
  }
  
  .common-table tbody tr td:last-child {
    border-right: 1px solid #e1e1e1;
  }
  
  .common-table>thead tr th {
    border: 1px solid #e1e1e1;
    border-bottom-color: #e1e1e1 !important;
    padding: 16px;
  }
  
  .common-table>thead tr th:not(:last-child) {
    border-right: 0;
  }
  
  .common-table>thead tr th:not(:first-child) {
    border-left: 0;
  }
  
  .common-table>thead tr th:first-child {
    border-top-left-radius: 8px;
  }
  
  .common-table>thead tr th:last-child {
    border-top-right-radius: 8px;
  }
  
  .common-table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }
  
  .common-table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }
  /* Table Css End */
  
  /* =================== Cart Personal Start ========================== */
  .order-summary {
    background-color: #F8F9FC;
    border-radius: 8px;
    padding: 24px;
  }
  
  .billing-list {
    padding-left: 0;
  }
  .billing-list__item {
    position: relative;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
  
  .billing-list__item:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  
  .billing-list__item:last-child::before {
    display: none;
  }
  
  .billing-list__item::before {
    position: absolute;
    content: "";
    width: calc(100% + 48px);
    left: 50%;
    height: 1px;
    transform: translateX(-50%);
    background-color: #e1e1e1;
    bottom: 0;
  }
  
  .font-heading {
    color: var(--heading-color);
  }
  
  .font-body {
    color: var(--paragraph-color);
  }
  
  input.bordered-input {
    border: 1px solid #ddd !important;
  }
  /* =================== Cart Personal End ========================== */
  
  /* ======================= Cart Payment Section Css Start ========================= */
  .cart-payment__box {
      padding: 84px 0;
      border-radius: 24px;
      background-color: #f3f3f3;
  }
  
  @media screen and (max-width: 991px) {
      .cart-payment__box {
          padding: 56px 0;
      }
  }
  
  @media screen and (max-width: 575px) {
      .cart-payment__box {
          padding: 24px 16px;
      }
  }
  /* Payment Method */
  .payment-method {
      margin-bottom: 72px;
  }
  
  @media screen and (max-width: 991px) {
      .payment-method {
          margin-bottom: 60px;
      }
  }
  
  @media screen and (max-width: 767px) {
      .payment-method {
          margin-bottom: 40px;
      }
  }
  
  .payment-method__item .form-check-input:checked + .form-check-label::before {
      background: var(--main-color);
  }
  
  .payment-method__item .form-check-input:checked + .form-check-label::after {
      background: #fff;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
  }
  
  .payment-method__item .form-check-label {
      background-color: #f5f7f9;
      position: relative;
      z-index: 1;
      border-radius: 4px;
      width: 94px;
      height: 64px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding: 4px;
      transition: 0.2s linear;
      width: 100%;
      cursor: pointer;
  }
  
  .payment-method__item .form-check-label::before, .payment-method__item .form-check-label::after {
      border-radius: inherit;
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: #efefef;
      z-index: -1;
      transition: 0.2s linear;
  }
  
  .payment-method__item .form-check-label::after {
      background: #f5f7f9;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
  }
  
  .payment-method .slick-initialized.slick-slider .slick-slide {
      margin: 0 4px;
  }
  
  .payment-method__wrapper {
      display: flex;
      gap: 12px;
      flex-wrap: wrap;
  }
  
  .payment-method__wrapper .payment-method__item {
      width: 100px;
  }
  
  /* Payment Method */
  .cart-payment-card {
      position: relative;
      background: var(--main-color);
      padding: 24px;
      z-index: 1;
      border-radius: 16px;
  }
  
  @media screen and (max-width: 575px) {
      .cart-payment-card {
          padding: 24px 16px;
      }
  }
  
  .cart-payment-card::before {
      position: absolute;
      content: "";
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background-color: #fff;
      left: 1px;
      top: 1px;
      border-radius: inherit;
      z-index: -1;
  }
  
  .total-bill {
      background-color: #f5f7f9;
      padding: 16px;
      border-radius: 4px;
  }
  
  .payment-method__slider {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    grid-gap: 8px;
  }
  /* ======================= Cart Payment Section Css End ========================= */
  
  /* ========================= Cart Thank You Section Css Start ======================= */
  .section-bg {
    background-color: #f5f7f9;
  }
  
  .footer-subscribe.section--bg::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 51%;
    top: 0;
    left: 0;
    background: #f4f6f8;
  }
  
  .footer-subscribe.section--bg {
    position: relative;
  }
  
  .cart-thank__box {
    border-radius: 24px;
    padding: clamp(1rem, -3.858rem + 7.843vw, 3rem);
    background-color: rgb(255 255 255 / 38%);
    backdrop-filter: blur(12px);
    box-shadow: 0px 20px 30px 0px rgba(197, 196, 201, 0.25);
  }
  
  @media screen and (max-width: 575px) {
    .cart-thank__box {
        padding: 0px;
        background-color: transparent;
    }
  }
  
  .cart-thank__img img {
    max-width: 56%;
  }
  
  /* thank card */
  .thank-card {
    padding: clamp(1rem, -2.643rem + 5.882vw, 2.5rem);
    border-radius: 24px;
    height: 100%;
    position: relative;
    z-index: 1;
    transition: 0.2s linear;
    background: transparent;
  }
  
  .thank-card:hover {
    box-shadow: 0px 20px 30px 0px rgba(197, 196, 201, 0.25);
    background: var(--main-color);
  }
  
  .thank-card::before {
    position: absolute;
    content: "";
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background: #fff;
    left: 1px;
    top: 1px;
    border-radius: inherit;
    z-index: -1;
    transition: 0.2s linear;
  }
  
  /* List Text Start */
  .list-text {
    border: 1px solid #e4e3e8;
    border-radius: 16px;
    padding-left: 0;
  }
  
  .list-text__item {
    border-bottom: 1px solid #e4e3e8;
  }
  
  .list-text__item .text {
    padding: 16px 24px;
    border-right: 1px solid #e4e3e8;
    font-size: 14px;
  }
  
  @media screen and (max-width: 1199px) {
    .list-text__item .text {
        padding: 16px;
    }
  }
  
  @media screen and (max-width: 424px) {
    .list-text__item .text {
        padding: 16px 8px;
        font-size: 12px;
    }
  }
  
  .list-text__item .text:first-child {
    width: 60%;
  }
  
  .list-text__item .text:last-child {
    border-right: 0;
  }
  
  .list-text__item:last-child {
    border-bottom: 0;
  }
  
  .text-main {
    color: var(--main-color) !important;
  }
  /* List Text End */
  /* ========================= Cart Thank You Section Css End ======================= */
  
  
  /* ========================== Account Page Css Start ============================= */
  .account {
    position: relative;
  }
  
  .account__img {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  
  @media screen and (max-width: 991px) {
    .account__img {
        display: none;
    }
  }
  
  .account__left {
    width: 43%;
    position: relative;
  }
  .account-thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  @media screen and (max-width: 991px) {
    .account__left {
        width: 60%;
    }
  }
  
  .account__left::before {
    position: absolute;
    content: "";
    width: 675px;
    height: 552px;
    background: linear-gradient(152deg, rgba(246, 246, 246, 0.3) 17.12%, rgba(94, 53, 242, 0.3) 105.91%);
    filter: blur(100px);
    left: -290px;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
  }
  
  .account__right {
    flex-grow: 1;
    padding-left: 32px;
    padding-right: 32px;
  }
  
  @media screen and (max-width: 991px) {
    .account__right {
        padding-bottom: 80px;
    }
  }
  
  @media screen and (max-width: 575px) {
    .account__right {
        padding-bottom: 60px;
    }
  }
  
  @media screen and (max-width: 575px) {
    .account__right {
        padding-left: 24px;
        padding-right: 24px;
    }
  }
  .account-content {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  /* ========================== Account Page Css End ============================= */
  
  
  /* Common Table Style Two Css Start */
  .common-table.style-two>thead tr th:first-child {
    border-radius: 0;
  }
  
  .common-table.style-two>thead tr th:last-child {
    border-radius: 0;
  }
  
  .common-table.style-two>thead tr th {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background-color: #f0ebff;
  }
  
  .table.common-table.style-two.style-two {
    margin-bottom: 0;
  }
  
  .common-table.style-two tbody tr:last-child td:first-child {
    border-radius: 0;
  }
  
  .common-table.style-two tbody tr:last-child td:last-child {
    border-radius: 0;
  }
  
  .common-table.style-two.style-two tbody tr td {
    border-left: 0;
    border-right: 0;
  }
  
  .common-table.style-two tbody tr:last-child td {
    border-bottom: 0;
  }
  
  
  .common-table.style-two>thead tr th {
    padding: 12px 16px;
  }
  /* Common Table Style Two Css End */
  
  .card {
    border-radius: 8px;
    overflow: hidden;
    border: 0;
    box-shadow: 0 1px 12px rgba(0,0,20,.08),0 1px 2px rgba(0,0,20,.08) !important;
  }
  
  .card-header, .card-footer {
    background-color: #fff;
    padding: 12px 16px;
  }
  
  
  .style-two .page-link {
    padding: 0;
  }
  
  .style-two .page-link {
    width: 38px;
    height: 38px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: #ddd;
  }
  
  .style-two .page-link:hover {
    background-color: var(--main-color);
    color: #fff;
  }
  
  /* ================================= Latest Css End =========================== */
.user-widget {
  background-color: #fff;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.user-widget .thumb {
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.user-widget .thumb img {
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center;
}


.user-widget .thumb img {
  width: 100%;
}

.user-widget .thumb img {
  height: 100%;
}



.user-widget {
  border: 1px solid #e7ecf1;
}

.user-widget-body {
  padding: 25px;
}

.user-widget .thumb {
  width: 150px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
}

.user-menu {
  padding: 1.25rem;
  border-top: 1px solid #e7ecf1;
}

.user-menu li a {
  padding: 6px 15px;
  display: inline-block;
}

.user-menu li a i {
  width: 30px;
}

.card-1 {
  padding: 0.9375rem 0.9375rem 0.625rem 0.9375rem;
  border: 1px solid #e7ecf1;
}

@media (max-width: 1399px) {
  .card-1 p {
      font-size: 0.8125rem;
  }
}

.card-1 .view-all {
  margin-top: 0.75rem;
  padding-top: 0.4375rem;
  border-top: 1px solid #e7ecf1;
}

.card-1 .view-all a {
  justify-content: space-between;
}

.list-style-none {
  list-style: none;
}


.card-1 {
  background-color: #fff;
  height: 100%;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.card-1 .view-all a {
  color: var(--main-color);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}


/* table css start */
.site-table thead th {
  color: #2e2e2e;
}
.site-table {
  margin-bottom: 0;
}

.site-table.site-thead thead th {
  padding: 0.75rem 1.25rem;
}

.site-table.site-thead thead th:first-child {
  border-radius: 8px 0 0 0;
  -webkit-border-radius: 8px 0 0 0;
  -moz-border-radius: 8px 0 0 0;
  -ms-border-radius: 8px 0 0 0;
  -o-border-radius: 8px 0 0 0;
}

.site-table.site-thead thead th:last-child {
  border-radius: 0 8px 0 0;
  -webkit-border-radius: 0 8px 0 0;
  -moz-border-radius: 0 8px 0 0;
  -ms-border-radius: 0 8px 0 0;
  -o-border-radius: 0 8px 0 0;
}

.site-table thead th {
  padding: 0.5rem 1.25rem;
  font-weight: 600;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 500;
  vertical-align: middle;
  background-color: #f7f7f7;
  border-bottom: 1px solid #d7dfe7 !important;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.site-table thead th:first-child {
  text-align: left;
}

.site-table thead th:last-child {
  text-align: right;
}

.site-table tbody td {
  border-top: none;
  border-bottom: 1px solid #dde4eb;
  padding: 0.625rem 1.25rem;
  color: #2e2e2e;
  vertical-align: middle;
}

.site-table tbody td:first-child {
  text-align: left;
}

.site-table tbody td:last-child {
  text-align: right;
}

.site-table tbody tr:last-child td {
  border-bottom: none;
}

.table-product img {
  width: 45px;
}

.table-product .p-name {
  padding-left: 0.9375rem;
}

[data-caption] {
  position: relative;
}

[data-caption]::before {
  position: absolute;
  content: attr(data-caption);
  font-weight: 700;
  top: 0;
  left: 0;
  padding: 0.8125rem 0.9375rem;
  display: none;
  font-size: 0.875rem;
}

@media (max-width: 991px) {
  .site-table thead {
      display: none;
  }

  .site-table tbody tr:nth-child(odd) {
      background-color: #f6f6f6;
  }

  .site-table tbody tr:last-child td {
      border-top: 1px solid rgba(0, 0, 0, 0.08);
  }

  .site-table tbody tr td {
      padding-right: 15px;
  }

  .site-table tbody tr td:last-child {
      padding-right: 15px;
  }

  .site-table tr th, .site-table tr td {
      display: block;
      padding-left: 45% !important;
      text-align: right !important;
  }

  .site-table tr th:first-child, .site-table tr td:first-child {
      border-top: none !important;
  }

  .site-table [data-caption]::before {
      display: block;
  }
}
/* table css end */


.badge {
  padding: 0 0 0 15px;
  position: relative;
  color: #052554;
  background-color: transparent;
  font-size: 14px;
}

.badge::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  width: 10px;
  height: 10px;
  background-color: #052554;
  border-radius: 50%;
  margin-top: -5px;
}

.badge-primary {
  color: #6777EF;
}

.badge-primary::before {
  background-color: #6777EF;
}

.badge-info {
  color: #0bb2d4;
}

.badge-info::before {
  background-color: #0bb2d4;
}

.badge-warning {
  color: #eb6709;
}

.badge-warning::before {
  background-color: #eb6709;
}

.badge-danger {
  color: #ff4c52;
}

.badge-danger::before {
  background-color: #ff4c52;
}

.badge-success {
  color: #11c26d;
}

.badge-success::before {
  background-color: #11c26d;
}

.icon-btn {
  width: 30px;
  height: 30px;
  background-color: var(--main-color);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.icon-btn:hover, .icon-btn {
  color: #fff;
}

.fs-14px {
  font-size: 14px !important;
}

.fs-12px {
  font-size: 12px !important;
}

.site-color {
  color: var(--main-color) !important;
}